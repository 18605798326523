

<template>
    <div :class="slideCssClass + (computedOptions.flex ? ' flex':'')">
        <a v-if="pictureURL" :href="slideHref" :target="slideTarget"  @click="onSlideClick" class="">       
            <img :src="pictureURL" :alt="getLocalizedText(labels.altImage)" class="background-picture">
        </a>
    </div>
</template>

<style>
/* d-block position-relative */
.swiper-slide.flex {
    width: auto;
    display: flex;
    height: auto;
}

.swiper-slide div.content {
    width:100%;
}

.swiper-slide a.background-picture {
    position:absolute;
    left: 0px;
    top: 0px;
    width: 100%;
}

.swiper-slide:not(.flex) img.background-picture {
    width: 100%;
}


</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount } from '@fwk-node-modules/vue'
import { CmsLabel } from '@fwk-client/modules/cms/types';
import { ActionOptions, useActions } from '@root/src/client/utils/actions';
import { useCmsModule } from '@fwk-client/utils/vue-3-migration';
import { Component } from '@fwk-client/modules/cms/helpers/components';

/*
slides = [
        {
            thumb: "images/slider/boxed/thumbs/2.jpg",
            link: undefined,
            image: "images/slider/boxed/2.jpg",
            labelPosition: " justify-content-start align-items-end",
            labelStyle: " bg-light text-dark ms-3 mb-3",
            altImageLabel: {
                "fr" : "Label de l'image"
            },
            label: {
                "fr" : "Le label du slide"
            }
        }
    ]
*/


/** @cmsOptions */
export interface SlideOptions {
    action:ActionOptions,
    pictureURL:string,
    flex:boolean,
    white:boolean,
}

/** @cmsLabels */
export interface SlideLabels {
    /** @cmsType CmsLabel */
    altImage: CmsLabel;
}

/** @cmsSlots */
export interface SliderSlots {
    buttons?:Component[]
}

export default defineComponent({
    props: {
        options: Object as PropType<SlideOptions>,
        labels: {
          type: Object as PropType<SlideLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<SliderSlots>,
        height: {
            type: [Number,String],
            required: false
        }
    },
    setup(props, context) {
        const cms = useCmsModule(); 
        
        const computedOptions:SlideOptions = {
            pictureURL:"",
            action:{},
            flex:false,
            white:false,
            ...props.options
        };

        const slideCssClass = computed(() => {
            var cssClass = "swiper-slide dark";
            if(computedOptions.white) {
                cssClass += " white";
            }
            if(props.height != undefined && typeof props.height == "string" && props.height == "auto") {
                cssClass += " h-auto";
            }
            else if(props.height != undefined && props.height > 0) {
                cssClass += " min-vh-"+props.height;
            }
            return cssClass;
        })

        onMounted(() => {
        })

        onBeforeUnmount(() => {
        })

        const { actionHref, actionTarget, onActionClick } = useActions();

        const slideHref = computed(() => {
            return actionHref(computedOptions.action);
        })

        const slideTarget = computed(() => {
            return actionTarget(computedOptions.action);
        })

        const onSlideClick = (evt:Event) => {
            onActionClick(evt, computedOptions.action);
        }

        return {
            slideHref,
            slideTarget,
            onSlideClick,
            slideCssClass,
            labels:props.labels,
            pictureURL:cms.utils.getComputedPictureURL(computedOptions.pictureURL),
            buttons:props.components ? props.components.buttons : [],
            computedOptions: {
                ...computedOptions
            }
        }

    }
})
</script>